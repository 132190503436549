import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { ConfirmationService, MessageService } from 'primeng/api';
import { firstValueFrom } from 'rxjs';
import { Indicator } from 'src/app/models/indicator';
import { ServerService } from 'src/app/services/server.service';
import { numberScale } from 'src/app/utils';

@Component({
  selector: 'app-dual-materiality',
  templateUrl: './dual-materiality.page.html',
  styleUrls: ['./dual-materiality.page.scss'],
})
export class DualMaterialityPage implements OnInit {
  mockIndicator = [{}];
  companyId: any;
  model: any[] = [];
  loading: boolean = false;
  totales: object = {};
  dialogDualMateriality: boolean = false;
  dmData: any[] = [];
  groupsWithDmData: any[] = [];
  form1: FormGroup;
  form2: FormGroup;
  form3: FormGroup;
  form4: FormGroup;
  form5: FormGroup;
  loadingModal: boolean = false;
  formDMGroup: FormGroup;
  dialogDualMaterialityForm: boolean = false;
  dialogGroupForm: boolean = false;
  selectedDm: any | null;
  edit: boolean = false;
  dmGroups: any[] = [];
  selectedIndicator: any;
  dialogForm: boolean = false;
  dialogGroup: boolean = false;
  selectedGroup: any | null = null;
  selectedTableGroup: any;
  uploadedFiles: { [step: string]: { [section: string]: File } } = {};
  loadingForm: boolean = false;
  numberScale = numberScale;
  impacts = [1, 2, 3, 4, 5];
  dialogDeleteDm: boolean = false;
  dialogDeleteGroup: boolean = false;
  financialRelevances = [5, 4, 3, 2, 1];
  loadingModalBtn: boolean = false;
  riskMatrixColors = [
    ['high', 'high', 'high', 'high', 'high'],
    ['medium', 'high', 'high', 'high', 'high'],
    ['low', 'medium', 'high', 'high', 'high'],
    ['low', 'low', 'medium', 'high', 'high'],
    ['low', 'low', 'low', 'medium', 'high'],
  ];
  riskRecords: any[] = [];
  existingFiles: {
    [step: number]: { [section: number]: { url: string; name: string } };
  } = {};
  keyMapping: { [mainKey: number]: { [subKey: number]: string } } = {
    1: {
      1: 'regulations_document',
      2: 'tendencies_document',
      3: 'expectations_document',
    },
    2: {
      1: 'internal_consultations_document',
      2: 'external_consultations_document',
    },
    3: {
      1: 'impact_document',
      2: 'financial_relevance_document',
    },
    4: {
      1: 'up_down_document',
      2: 'lca_document',
    },
    5: {
      1: 'transparency_document',
      2: 'accordance_document',
    },
  };

  constructor(
    private serverService: ServerService,
    private router: Router,
    private fb: FormBuilder,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.form1 = this.fb.group({
      regulations_text: [''],
      tendencies_text: [''],
      expectations_text: [''],
    });

    this.form2 = this.fb.group({
      internal_consultations_text: [''],
      external_consultations_text: [''],
    });

    this.form3 = this.fb.group({
      impact: [''],
      impact_text: [''],
      financial_relevance: [''],
      financial_relevance_text: [''],
    });

    this.form4 = this.fb.group({
      up_down_text: [''],
      lca_text: [''],
    });

    this.form5 = this.fb.group({
      transparency_text: [''],
      accordance_text: [''],
    });

    this.formDMGroup = this.fb.group({
      name: ['', Validators.required],
    });

    this.companyId = sessionStorage.getItem('companyId');
    this.getDualMaterialitiesByGroup();
    this.getDMGroups();
    this.recuperarDatosMenu();
  }

  /**
   * Obtener registros de doble materialidad por grupos (estos datos se pintarán en la matriz de riesgos)
   */
  getDualMaterialitiesByGroup() {
    this.serverService.getData(`/api/dual-materialities/byGroup`).subscribe({
      next: (response) => {
        if (response.data) {
          this.getRiskMatrixData(response.data);
        }
      },
      error: (err) => {
        console.error(
          'Error al obtener los registros de doble materialidad por grupos',
          err
        );
      },
    });
  }

  /**
   * Definir estilos en matriz de riesgos
   * @param rowIndex
   * @param colIndex
   * @returns
   */
  getCellClass(rowIndex: number, colIndex: number): string {
    return this.riskMatrixColors[rowIndex][colIndex];
  }

  /**
   * Función que filtra los registros para devolver solo aquellos que coinciden con la frecuencia e impacto dados e imprimirlos en la celda actual
   * @param frequency
   * @param impact
   * @returns
   */
  getRecordsForCell(frequency: string, impact: string): any[] {
    return this.riskRecords.filter(
      (record) => record.frequency === frequency && record.impact === impact
    );
  }

  /**
   * Manejar pulsación de botón para nuevo grupo o doble materialidad para multiples indicadores
   * @param event
   */
  handleAddButton(event) {
    // this.dialogForm = true;
    this.dialogGroup = true;
    this.dialogForm = false;
  }

  /**
   * Manejador de selección de tipo de formulario
   */
  formSelected(type: number) {
    if (type === 1) {
    }

    if (type === 2) {
      this.dialogGroup = true;
      this.dialogForm = false;
    }
  }

  /**
   * Mostrar modal de creación/edición de grupo
   */
  showDialog(group?: any) {
    if (group) {
      this.selectedTableGroup = group;
      this.formDMGroup.patchValue(group);
    }

    this.edit = group ? true : false;
    this.dialogGroup = false;
    this.dialogGroupForm = true;
  }

  /**
   * Obtener grupos de doble materialidad
   */
  async getDMGroups() {
    try {
      const response = await firstValueFrom(
        this.serverService.getData(`/api/dual-materiality/groups`)
      );
      this.dmGroups = response.data ? response.data : [];
    } catch (err) {
      console.error('Error al obtener el listado de grupos', err);
    }
  }

  /**
   * Generador de datos en las celdas
   */
  getRiskMatrixData(data: any[]): void {
    this.groupsWithDmData = data;

    // Procesamos cada grupo
    this.groupsWithDmData.forEach((group) => {
      group.matrixData = {};

      // Verificamos que el grupo tenga dual_materiality y que sea un arreglo
      if (group.dual_materiality && Array.isArray(group.dual_materiality)) {
        group.dual_materiality.forEach((dm) => {
          // Obtenemos los valores numéricos de impact y financial_relevance
          const impact = Number(dm.dual_materiality3?.impact);
          const financialRelevance = Number(
            dm.dual_materiality3?.financial_relevance
          );

          // Verificamos que ambos valores existan y sean números válidos
          if (impact && financialRelevance) {
            // Inicializamos las estructuras si no existen
            if (!group.matrixData[financialRelevance]) {
              group.matrixData[financialRelevance] = {};
            }
            if (!group.matrixData[financialRelevance][impact]) {
              group.matrixData[financialRelevance][impact] = [];
            }

            // Agregamos el registro a la celda correspondiente
            group.matrixData[financialRelevance][impact].push(dm);
          }
        });
      }
    });
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    this.serverService
      .getData('/api/menusWithIndicatorName/' + this.companyId)
      .subscribe({
        next: (response) => {
          this.model = response.data;
          this.loading = true;
        },
        error: (err) => {
          console.error('Error al obtener datos del menú', err);
          this.loading = true;
        },
      });
  }

  /**
   * Manejador de modal de formulario de doble materialidad
   */
  showDualMaterialityDialog(indicator: Indicator) {
    this.selectedIndicator = indicator;
    this.loadingModal = true;

    this.serverService
      .getData(`/api/dual-materialities/indicator/${indicator.id}`)
      .subscribe({
        next: (response) => {
          this.dmData = response.data
            ? response.data[0]['dual_materiality'].map((record) => {
                // Llamamos a la función que verifica si todos los formularios están completos
                const isComplete = this.checkDualMaterialityCompletion(record);

                // Retornamos el registro original con un nuevo campo "isComplete"
                return {
                  ...record,
                  isComplete: isComplete, // Añadimos el campo 'isComplete'
                };
              })
            : [];
        },
        error: (err) => {
          console.error(
            'Error al obtener datos de doble materialidad de un indicador',
            err
          );
        },
        complete: () => {
          this.loadingModal = false;
          this.dialogDualMateriality = true;
        },
      });
  }

  /**
   * Validador de formularios completos por separado
   * @param record
   * @returns
   */
  checkDualMaterialityCompletion(record: any): boolean {
    const materialityKeys = [
      'dual_materiality1',
      'dual_materiality2',
      'dual_materiality3',
      'dual_materiality4',
      'dual_materiality5',
    ];

    // Recorremos cada objeto de dual materiality
    for (const key of materialityKeys) {
      const materiality = record[key];
      if (materiality) {
        for (const field in materiality) {
          if (
            !field.includes('hash') &&
            field !== 'created_at' &&
            field !== 'updated_at' &&
            (materiality[field] === null || materiality[field] === '')
          ) {
            return false; // Si algún campo no está cumplimentado, devolvemos false
          }
        }
      }
    }
    return true; // Si todos los campos están cumplimentados, devolvemos true
  }

  /**
   * Manejador de cierre de modal de doble materialidad
   */
  handleHideDualMateriality() {
    this.selectedGroup = null;
  }

  /**
   * Enviar formulario de doble materialidad
   */
  onSubmitForm() {
    if (!this.selectedGroup) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Debe seleccionar un grupo de doble materialidad',
      });
    }

    const formData = new FormData();
    const allFormData = {
      ...this.form1.value,
      ...this.form2.value,
      ...this.form3.value,
      ...this.form4.value,
      ...this.form5.value,
    };

    // Procesar datos del formulario excluyendo los vacíos
    Object.keys(allFormData).forEach((key) => {
      const value = allFormData[key];
      if (value !== null && value !== '') {
        formData.append(key, value);
      }
    });

    // Procesar los documentos y mapear las claves
    Object.keys(this.uploadedFiles).forEach((mainKey) => {
      Object.keys(this.uploadedFiles[mainKey]).forEach((subKey) => {
        const file = this.uploadedFiles[mainKey][subKey];
        const key = this.keyMapping[Number(mainKey)]?.[Number(subKey)];
        if (key && file) {
          formData.append(key, file);
        }
      });
    });

    // Añadir indicador y grupo si existe
    if (this.selectedIndicator) {
      formData.append('indicator_id', this.selectedIndicator.id);
      formData.append('dual_materiality_group_id', this.selectedGroup);
    }

    this.loadingForm = true;

    const request = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materialities/${this.selectedDm.id}`,
          formData
        )
      : this.serverService.sendData(`/api/dual-materialities`, formData);

    request.subscribe({
      next: (response) => {
        if (response.data) {
          this.dialogDualMaterialityForm = false;
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: 'Registro de doble materialidad guardado correctamente',
          });
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error(
          'Error al guardar el registro de doble materialidad',
          err
        );
        if (err.status === 409) {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Ya hay un registro de doble materialidad con este grupo asignado, inténtelo con otro diferente',
          });
        } else {
          this.messageService.add({
            severity: 'warn',
            summary: 'Aviso',
            detail:
              'Error al guardar el registro de doble materialidad, inténtelo de nuevo',
          });
        }
        this.loadingForm = false;
      },
    });
  }

  /**
   * Enviar formulario de grupo de doble materialidad
   */
  async onSubmitGroupForm() {
    this.formDMGroup.markAllAsTouched();

    if (this.formDMGroup.invalid) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Revise el formulario',
      });
    }

    const formData = new FormData();
    const formValue = this.formDMGroup.value;

    // Nombre duplicado
    const isDuplicated = this.dmGroups.some(
      (group) =>
        group.name === formValue.name && this.companyId === group.company_id
    );

    if (isDuplicated) {
      return this.messageService.add({
        severity: 'warn',
        summary: 'Aviso',
        detail: 'Ya existe un grupo con el mismo nombre',
      });
    }

    formData.append('name', formValue.name);
    formData.append('company_id', this.companyId);

    this.loadingForm = true;

    const requestObservable = this.edit
      ? this.serverService.updatePostData(
          `/api/dual-materiality/groups/${this.selectedTableGroup.id}`,
          formData
        )
      : this.serverService.sendData(`/api/dual-materiality/groups`, formData);

    const successDetail = this.edit
      ? 'El grupo de doble materialidad se ha actualizado correctamente'
      : 'El grupo de doble materialidad se ha creado correctamente';

    requestObservable.subscribe({
      next: (response) => {
        if (response.data) {
          this.getDMGroups();
          this.getDualMaterialitiesByGroup();
          this.messageService.add({
            severity: 'success',
            summary: 'OK',
            detail: successDetail,
          });
          this.formDMGroup.reset();
          this.dialogGroupForm = false;
        }
        this.loadingForm = false;
      },
      error: (err) => {
        console.error('Error al enviar formulario', err);
        this.messageService.add({
          severity: 'warn',
          summary: 'Aviso',
          detail: 'Ocurrió un error registrando el grupo, inténtelo de nuevo',
        });
        this.loadingForm = false;
      },
    });
  }

  /**
   * Observador de archivos para el componente p-fileUpload
   */
  getUploadedFiles(step: number, section: number): File[] {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      return [this.uploadedFiles[step][section]];
    }
    return [];
  }

  /**
   * Manejador de subida de archivo
   * @param event
   */
  onFileSelect(event: any, step: number, section: number): void {
    const selectedFiles = event.files;
    if (selectedFiles && selectedFiles.length > 0) {
      const selectedFile = selectedFiles[0];
      if (!this.uploadedFiles[step]) {
        this.uploadedFiles[step] = {};
      }
      this.uploadedFiles[step][section] = selectedFile;
    }
  }

  /**
   * Manejar eliminación de archivo
   * @param event
   */
  deleteFileSelected(event: any, step: number, section: number): void {
    if (this.uploadedFiles[step] && this.uploadedFiles[step][section]) {
      delete this.uploadedFiles[step][section];
    }
  }

  /**
   * Eliminar un grupo de doble materialidad
   */
  deleteGroup(group) {
    this.selectedGroup = group;
    if (this.selectedGroup) {
      this.dialogDeleteGroup = true;
    }
  }

  /**
   * Mostrar modal de formulario de creación/edición de registro de doble materialidad
   */
  async showDualMaterialityFormDialog(dm: any) {
    this.loadingModal = true;
    await this.getDMGroups();

    if (dm) {
      this.selectedDm = dm;
      this.selectedGroup = dm.dual_materiality_group_id;

      this.dmGroups = this.dmGroups.filter(
        (group) =>
          this.dmData.some((d) => d.dual_materiality_group_id !== group.id) ||
          group.id === dm.dual_materiality_group_id // Permitir que el grupo del registro actual se muestre
      );
      // Rellenar los formularios con los datos existentes
      if (dm.dual_materiality1) {
        this.form1.patchValue({
          regulations_text: dm.dual_materiality1.regulations_text,
          tendencies_text: dm.dual_materiality1.tendencies_text,
          expectations_text: dm.dual_materiality1.expectations_text,
        });
      }

      if (dm.dual_materiality2) {
        this.form2.patchValue({
          internal_consultations_text:
            dm.dual_materiality2.internal_consultations_text,
          external_consultations_text:
            dm.dual_materiality2.external_consultations_text,
        });
      }

      if (dm.dual_materiality3) {
        this.form3.patchValue({
          impact: +dm.dual_materiality3.impact,
          impact_text: dm.dual_materiality3.impact_text,
          financial_relevance: +dm.dual_materiality3.financial_relevance,
          financial_relevance_text:
            dm.dual_materiality3.financial_relevance_text,
        });
      }
      if (dm.dual_materiality4) {
        this.form3.patchValue({
          up_down_text: dm.dual_materiality4.up_down_text,
          lca_text: dm.dual_materiality4.lca_text,
        });
      }
      if (dm.dual_materiality5) {
        this.form3.patchValue({
          transparency_text: dm.dual_materiality5.transparency_text,
          accordance_text: dm.dual_materiality5.accordance_text,
        });
      }

      // Manejar los documentos existentes
      this.populateExistingFiles(dm);
    } else {
      this.dmGroups = this.dmGroups.filter(
        (group) =>
          !this.dmData.some((d) => d.dual_materiality_group_id === group.id)
      );
    }

    this.edit = dm ? true : false;
    this.dialogDualMaterialityForm = true;
    this.dialogDualMateriality = false;
    this.loadingModal = false;
  }

  /**
   * Función para procesar documentos existentes desde el registro de un indicador
   */
  populateExistingFiles(dm: any): void {
    // Inicializar existingFiles
    this.existingFiles = {};

    // Paso 1
    if (dm.dual_materiality1) {
      const step = 1;
      this.existingFiles[step] = {};

      if (dm.dual_materiality1.regulations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality1.regulations_document,
          name: 'regulations_document.pdf', // Puedes obtener el nombre real si está disponible
        };
      }

      if (dm.dual_materiality1.tendencies_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality1.tendencies_document,
          name: 'tendencies_document.pdf',
        };
      }

      if (dm.dual_materiality1.expectations_document) {
        this.existingFiles[step][3] = {
          url: dm.dual_materiality1.expectations_document,
          name: 'expectations_document.pdf',
        };
      }
    }

    // Paso 2
    if (dm.dual_materiality2) {
      const step = 2;
      this.existingFiles[step] = {};

      if (dm.dual_materiality2.internal_consultations_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality2.internal_consultations_document,
          name: 'internal_consultations_document.pdf',
        };
      }

      if (dm.dual_materiality2.external_consultations_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality2.external_consultations_document,
          name: 'external_consultations_document.pdf',
        };
      }
    }

    // Paso 3
    if (dm.dual_materiality3) {
      const step = 3;
      this.existingFiles[step] = {};

      if (dm.dual_materiality3.impact_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality3.impact_document,
          name: 'impact_document.pdf',
        };
      }

      if (dm.dual_materiality3.financial_relevance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality3.financial_relevance_document,
          name: 'financial_relevance_document.pdf',
        };
      }
    }

    // Paso 4
    if (dm.dual_materiality4) {
      const step = 4;
      this.existingFiles[step] = {};

      if (dm.dual_materiality4.up_down_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality4.up_down_document,
          name: 'up_down_document.pdf',
        };
      }

      if (dm.dual_materiality4.lca_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality4.lca_document,
          name: 'lca_document.pdf',
        };
      }
    }

    // Paso 5
    if (dm.dual_materiality5) {
      const step = 5;
      this.existingFiles[step] = {};

      if (dm.dual_materiality5.transparency_document) {
        this.existingFiles[step][1] = {
          url: dm.dual_materiality5.transparency_document,
          name: 'transparency_document.pdf',
        };
      }

      if (dm.dual_materiality5.accordance_document) {
        this.existingFiles[step][2] = {
          url: dm.dual_materiality5.accordance_document,
          name: 'accordance_document.pdf',
        };
      }
    }

    console.log(this.existingFiles);
  }

  /**
   * Eliminar un registro de doble materialidad de un indicador
   */
  deleteDm(dm) {
    this.dialogDeleteDm = true;
    this.selectedDm = dm;
  }

  /**
   * Función auxiliar para selección de eliminación de doble materialidad
   * @param option
   */
  onClickDeleteDm(option: boolean) {
    this.loadingModalBtn = true;
    if (option) {
      this.serverService
        .deleteData(`/api/dual-materialities/${this.selectedDm.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              // Borrado local para actualizar vista de la tabla de DM
              this.dmData = this.dmData.filter(
                (dm) => response.data.id !== dm.id
              );
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail:
                  'Registro de doble materialidad eliminado correctamente',
              });
            }
            this.getDMGroups();
            this.dialogDeleteDm = false;
            this.loadingModalBtn = false;
            this.selectedDm = null;
          },
          error: (err) => {
            console.error('Error al eliminar el registro', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el registro de doble materialidad, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteDm = false;
    }
  }

  /**
   * Función auxiliar para selección de eliminación de grupo de doble materialidad
   * @param option
   */
  onClickDeleteGroup(option: boolean) {
    if (option) {
      this.loadingModalBtn = true;
      this.serverService
        .deleteData(`/api/dual-materiality/groups/${this.selectedGroup.id}`)
        .subscribe({
          next: (response) => {
            if (response.data) {
              this.messageService.add({
                severity: 'success',
                summary: 'OK',
                detail: 'Grupo de doble materialidad eliminado correctamente',
              });
            }
            this.getDMGroups();
            this.dialogDeleteGroup = false;
            this.loadingModalBtn = false;
            this.selectedGroup = null;
          },
          error: (err) => {
            console.error('Error al eliminar el grupo', err);
            this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              detail:
                'Ocurrió un error al eliminar el grupo, inténtelo de nuevo',
            });
          },
        });
    } else {
      this.dialogDeleteGroup = false;
    }
  }

  /**
   * Abrir documento
   */
  openDocument(document) {
    window.open(document.url, '_blank');
  }
}
