import { Component, OnInit } from '@angular/core';
import { MenuItem } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { ServerService } from '../services/server.service';
import { PermissionsService } from '../services/permissions.service';
import { lastValueFrom, Subscription } from 'rxjs';
import { EventsService } from '../services/events.service';

@Component({
  selector: 'app-menu',
  templateUrl: './app.menu.component.html',
})
export class AppMenuComponent implements OnInit {
  model: any[] = [];
  test = 'clase-test';
  menuData: any;
  companyId: any;
  user: any;
  userByCompany: any;
  loading: boolean = true;
  private eventSubscription: Subscription;

  constructor(
    public layoutService: LayoutService,
    private serverService: ServerService,
    private permissionsService: PermissionsService,
    private eventService: EventsService
  ) {
    this.companyId = sessionStorage.getItem('companyId');
  }

  ngOnInit() {
    if (this.companyId) {
      this.recuperarDatosMenu();
    }

    // Suscripción al evento que se ha emitido, se mantiene a la escucha desde que el componente está activo
    this.eventSubscription = this.eventService.events$.subscribe((event) => {
      if (event.event === 'menuUpdated') {
        this.recuperarDatosMenu();
      }
    });
  }

  ngOnDestroy() {
    if (this.eventSubscription) {
      this.eventSubscription.unsubscribe();
    }
  }

  /**
   * Función para parsear la respuesta JSON que devuelve el servidor.
   * @param {Object} response - Respuesta devuelta por el servidor.
   * @returns {Array} - Array con los elementos del menú.
   */
  parsearRespuesta(response) {
    return JSON.parse(response.data.json_menu);
  }

  agregarRouterLink(items) {
    const dataToStore: any[] = []; // Array para almacenar los datos relevantes
    items.items.forEach((item) => {
      // Si el elemento del menú tiene hijos, iterar sobre ellos para agregar el routerLink
      if (item.items && item.items.length > 0) {
        this.agregarRouterLink(item);
      } else {
        item.routerLink = ['base-page', item.id];

        item.command = (event) => this.saveLocalStorage(item.label, item.icon);

        delete item.items; // Eliminar la propiedad 'items' si no tiene hijos
      }

      // Eliminar la propiedad 'items' solo si está vacía
      if (item.items && item.items.length === 0) {
        delete item.items;
      }
    });
  }

  saveLocalStorage(label: string, icon: string) {
    let dataToStore: any[] = [];
    dataToStore.push({ label: label, icon: icon });
    localStorage.setItem('LabelIcon', JSON.stringify(dataToStore));
  }

  /**
   * Agrega elementos adicionales al array de datos del menú.
   */
  async agregarElementosAdicionales(noData?: boolean) {
    if (noData) {
      this.model['items'] = [];
    }

    // Check a true para lectura en menú de opciones de permisos de indicadores
    await this.getUserByCompany();

    const dashboard = {
      label: 'Dashboard',
      icon: 'pi pi-home',
      routerLink: ['/dashboard'],
    };

    this.model['items'].unshift(dashboard); // Agregar nuevo elemento al inicio

    //variable Ajustes (control acceso por permisos)
    let ajustes: any;
    //check ajustes = usuarios que puedan editar indicadores (superadmin y admin)
    if (this.permissionsService.checkSectionPermission(this.userByCompany, 2, 3)) {
      ajustes = {
        label: 'Ajustes',
        icon: 'pi pi-cog',
        items: [
          {
            label: 'Menú',
            icon: null,
            items: [],
          },
        ],
      };

      //check section Menu. ajustes > Marcadores (superadmin)
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 1, 2)
      ) {
        ajustes.items[0].items.push({
          label: 'Marcadores',
          icon: null,
          routerLink: ['/form'],
        });
      }
      
      //check section Indicator. ajustes > Indicadores
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 2, 3)
      ) {
        ajustes.items[0].items.push({
          label: 'Indicadores',
          icon: null,
          routerLink: ['/indicadores'],
        });
      }      

      //check section IndicatorPermission
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 3, 2)
      ) {
        ajustes.items.push({
          label: 'Permisos indicadores',
          icon: null,
          routerLink: ['/indicator-permissions'],
        });
      }

      //check section ActionPermission
      if (
        this.permissionsService.checkSectionPermission(this.userByCompany, 9, 2)
      ) {
        ajustes.items.push({
          label: 'Permisos acciones',
          icon: null,
          routerLink: ['/action-permissions'],
        });
      }
    }

    // Aquí agregamos la lógica de permisos para "Repositorio de información"
    let repositorioInformacionItems: any[] = [];
    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 18, 1)
    ) {
      repositorioInformacionItems.push({
        label: 'Contexto de la organización',
        icon: 'null',
        routerLink: ['/organization-context'],
      });
    }

    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 19, 1)
    ) {
      repositorioInformacionItems.push({
        label: 'Partes interesadas',
        icon: 'null',
        routerLink: ['/concerned-parties'],
      });
    }

    if (
      this.permissionsService.checkSectionPermission(this.userByCompany, 20, 1)
    ) {
      repositorioInformacionItems.push({
        label: 'Alcance del sistema',
        icon: 'null',
        routerLink: ['/system-scopes'],
      });
    }

    // Info de tipos de usuarios en ESG
    if (this.user.id === 1) {
      repositorioInformacionItems.push(
        {
          label: 'Asociados',
          icon: 'null',
          routerLink: ['/users-associates'],
        },
        {
          label: 'Clientes',
          icon: 'null',
          routerLink: ['/users-customers'],
        },
        {
          label: 'Empleados',
          icon: 'null',
          routerLink: ['/users-employees'],
        },
        {
          label: 'Proveedores',
          icon: 'null',
          routerLink: ['/users-suppliers'],
        }
      );
    }

    if (repositorioInformacionItems.length > 0) {
      this.model['items'].push({
        label: 'Repositorio de información',
        icon: 'pi pi-info-circle',
        items: repositorioInformacionItems,
      });
    }

    // Última asignación de menus a model['items']
    this.model['items'].push(
      {
        label: 'Reports',
        icon: 'pi pi-book',
        items: [
          {
            label: 'Panel',
            icon: 'pi pi-sliders-h',
            routerLink: ['/panel'],
          },
        ],
      },
      {
        label: 'Doble materialidad',
        icon: 'pi pi-angle-double-right',
        items: [
          {
            label: 'Acceder',
            icon: 'null',
            routerLink: ['/dual-materiality'],
          },
        ],
      },
      {
        label: 'Empresas',
        icon: 'pi pi-share-alt',
        items: [
          {
            label: 'Acceder',
            icon: 'null',
            routerLink: ['/business-networking'],
          },
        ],
      },
      ajustes
    );

    this.loading = false;
  }

  /**
   * Obtener datos sobre secciones y roles del usuario con sus permisos
   */
  async getUserByCompany() {
    try {
      // Esperamos a que se resuelva
      const userResponse = await lastValueFrom(
        this.serverService.getDataUsersApi('/api/user')
      );
      if (userResponse) {
        this.user = userResponse;
        // Esperamos a que se resuelva
        const userByCompany = await lastValueFrom(
          this.serverService.getDataUsersApi(
            `/api/companies/${this.companyId}/users/${this.user?.id}`
          )
        );
        if (userByCompany) {
          this.userByCompany = userByCompany.data ? userByCompany.data : [];
        }
      }
    } catch (err) {
      console.error(
        'Error al obtener datos usuario y compaías de usuario:',
        err
      );
    } finally {
    }
  }

  /**
   * Recupera los datos del menú desde el servidor según un id de menú dado
   */
  recuperarDatosMenu() {
    // Obtengo id de menú a partir de id de empresa
    this.serverService
      .getData('/api/menus/' + parseInt(this.companyId))
      .subscribe({
        next: (response) => {
          if (response) {
            this.model = this.parsearRespuesta(response);
            this.agregarRouterLink(this.model);
            this.agregarElementosAdicionales(false);
          }
        },
        error: (err) => {
          console.log(err);
          this.agregarElementosAdicionales(true);
        },
      });
  }
}
