import { Component, ElementRef, ViewChild } from '@angular/core';
import { MenuItem, MessageService } from 'primeng/api';
import { LayoutService } from './service/app.layout.service';
import { Observable } from 'rxjs';
import { HttpClient } from '@angular/common/http';
import { catchError, concatMap, map } from 'rxjs/operators';
import { throwError } from 'rxjs';
import { Router } from '@angular/router';
import { HttpHeaders } from '@angular/common/http';
import { UserService } from '../services/user.service';
import { ServerService } from '../services/server.service';
import { Indicator } from '../models/indicator';
import { environment } from 'src/environments/environment';
@Component({
  selector: 'app-topbar',
  templateUrl: './app.topbar.component.html',
})
export class AppTopBarComponent {
  menu: MenuItem[] = [];
  @ViewChild('searchinput') searchInput!: ElementRef;
  @ViewChild('menubutton') menuButton!: ElementRef;
  searchActive: boolean = false;
  private apiUrl = 'http://localhost:8080';
  userInfo: any;
  indicators: Indicator[] = [];
  filteredItems: any[] = [];
  selectedItem: any;

  constructor(
    public layoutService: LayoutService,
    private http: HttpClient,
    private router: Router,
    private serverService: ServerService,
    private messageService: MessageService
  ) {}

  ngOnInit() {
    this.getIndicators();
  }

  /**
   * Obtener listado de indicadores
   */
  getIndicators() {
    this.serverService.getData('/api/indicators/').subscribe({
      next: (response) => {
        this.indicators = response.data;
      },
      error: (err) => {},
    });
  }

  /**
   * Filtrado de indicadores en el buscador
   * @param query
   */
  filterItems(query: string) {
    if (query) {
      this.filteredItems = this.indicators.filter(
        (item) =>
          item.name && item.name.toLowerCase().includes(query.toLowerCase())
      );
    } else {
      this.filteredItems = [];
    }
  }

  /**
   * Manejador de opción seleccionada y redirección a pantalla del indicador según su tipo
   * @param item
   */
  selectItem(indicator: any) {
    let isCuantitative: boolean = false;
    let isCualitative: boolean = false;
    this.selectedItem = indicator;
    this.searchActive = false;
    this.filteredItems = [];

    // Comprobar de que tipo es el indicador antes de navegar
    this.serverService
      .getData('/api/indicators/' + indicator.id)
      .pipe(
        concatMap((response) => {
          if (response.data && response.data.indicator_configuration_id) {
            isCuantitative = true;
          }
          return this.serverService.getData(
            '/api/indicator/' + indicator.id + '/indicator-documents'
          );
        })
      )
      .subscribe({
        next: (response) => {
          if (response.data && response.data.length > 0) {
            isCualitative = true;
          }

          // Si es indicador cuantitativo
          if (isCuantitative) {
            this.router.navigate(['base-indicator/' + indicator.id]);
          }

          // Si es indicador cualitativo
          if (isCualitative) {
            this.router.navigate(['base-qualitative/' + indicator.id]);
          }

          if (!isCualitative && !isCuantitative) {
            return this.messageService.add({
              severity: 'warn',
              summary: 'Aviso',
              key: 'tst',
              detail:
                'El indicador seleccionado no tiene tipo aún, inténtelo con otro diferente',
            });
          }
        },
        error: (err) => {
          console.error('Error:', err);
        },
      });
  }

  onMenuButtonClick() {
    this.layoutService.onMenuToggle();
  }

  activateSearch() {
    this.searchActive = true;
    setTimeout(() => {
      this.searchInput.nativeElement.focus();
    }, 100);
  }

  deactivateSearch() {
    this.searchActive = false;
  }

  removeTab(event: MouseEvent, item: MenuItem, index: number) {
    this.layoutService.onTabClose(item, index);
    event.preventDefault();
  }

  get layoutTheme(): string {
    return this.layoutService.config().layoutTheme;
  }

  get colorScheme(): string {
    return this.layoutService.config().colorScheme;
  }

  get logo(): string {
    const path = 'assets/layout/images/icloudcompliancelogo-';
    const logo =
      this.layoutTheme === 'primaryColor' &&
      !(this.layoutService.config().theme == 'yellow')
        ? 'light.png'
        : this.colorScheme === 'light'
        ? 'dark.png'
        : 'light.png';
    return path + logo;
  }

  get tabs(): MenuItem[] {
    return this.layoutService.tabs;
  }

  logout() {
    this.postLogout()
      .pipe(
        map((data) => {
          window.location.href = environment.usersURL + '/login';
          return data;
        }),
        catchError((error) => {
          console.error('Error!', error);
          return throwError(error);
        })
      )
      .subscribe();
  }

  getToken(): string | null {
    return sessionStorage.getItem('token');
  }

  postLogout(): Observable<any> {
    const headers = new HttpHeaders({
      Authorization: 'Bearer ' + this.getToken(),
    });
    return this.http.post(`${environment.usersApiURL}/api/logout`, null, {
      headers: headers,
    });
  }

  goToUsers() {
    window.location.href = environment.usersURL;
  }
}
